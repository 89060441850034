<template>
  <a-modal title="新建公告" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="提交" width="47.25%"
    @ok="handleOk" @cancel="handleCancel" class="journal-modal">
    <a-form :form="form" layout="vertical">
      <a-form-item label="公告标题" >
        <a-input v-decorator="['title', { rules: [{ required: true, message: '请输入公告标题!' }] }]" placeholder="请输入公告标题" />
      </a-form-item>
      <a-form-item label="公告时间" >
          <a-range-picker @change="onChange" v-decorator="['timer', { rules: [{ required: true, message: '请选择公告时间!' }] }]" style="width:100%" />
      </a-form-item>
      <a-form-item label="发送给谁">
        <div>
          <a-popover v-model="isCheckWarp" title="选择" trigger="click">
            <div slot="content">
              <checkWarp />
            </div>
            <div style="display:flex;align-items:center;flex-wrap:wrap;min-height:39px">
              <a-tag style="margin:5px" v-for="(item,index) in check_name_list" :key="index">{{item.filter_name || item.department_name}}</a-tag>
              <a-icon style='fontSize:18px' type="plus-circle" />
            </div>
          </a-popover>
        </div>
      </a-form-item>
      <a-form-item label="公告正文" >
          <l-editor v-decorator="['content', { rules: [{ required: true, message: '请填写公告正文!' }] }]" name="content" :data="editor.content"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
    import moment from 'moment'
    import checkWarp from '@/views/market/huidongApply/coachDaily/CheckWarp'
    export default {
        name:'editModal',
        inject: ['parent'],
        provide() {
            return {
                parent: this
            }
        },
        data() {
            return {
                visible: false,
                confirmLoading: false,
                isCheckWarp: false,
                editor: {},
                form: this.$form.createForm(this),
                check_name_list:[],
            }
        },
        components: { checkWarp },
        created () {
            this.$ls.remove('check_name_list')
            this.$ls.remove('send_employee_ids')
            this.$ls.remove('send_employee_list')
            this.$ls.remove('send_structure_ids')
            this.$ls.remove('send_structure_list')
            this.visible = true
        },
        methods: {
            async handleOk() {
                let content = this.editor.content
                this.form.setFieldsValue({content:this.editor.content})
                let params = await this.form.validateFields()
                let obj = {
                    title:params.title,
                    content:this.editor.content,
                    start_time:moment(params.timer[0]).format("YYYY-MM-DD"),
                    end_time:moment(params.timer[1]).format("YYYY-MM-DD"),
                    send_employee_ids:JSON.parse(this.$ls.get('send_employee_ids')) || [],
                    send_structure_ids:JSON.parse(this.$ls.get('send_structure_ids')) || []
                }
                await this.$store.dispatch('oaAnnounceAddAction', obj)
                .then(res=>{
                    this.$message.success('操作成功~')
                    this.parent.hideEditModal(1)
                })
                .catch(err=>{
                    this.$message.error('操作失败~')
                })

            },
            handleCancel(){
                this.parent.hideEditModal()
            },
            onChange(date, dateString) {
                console.log(date, dateString)
            },
            hideCheckWarp(val){
                if(val){
                    this.check_name_list = JSON.parse(this.$ls.get('check_name_list'))
                }
                this.isCheckWarp = false
            },
            changeEditor(key, value) {
                this.editor[key] = value
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>