<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>工作台</a-breadcrumb-item>
            <a-breadcrumb-item>公告</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="announce-main-box">
            <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
                <a-tab-pane :key="0">
                    <span slot="tab">公告</span>
                </a-tab-pane>
            </a-tabs>
            <div class="clearfix table-tools">
                <div class="buttons">
                    <a-form layout='inline'>
                        <a-form-item>
                            <a-button icon="plus" type="dashed" @click="showEditModal()">写公告</a-button>
                        </a-form-item>
                    </a-form>
                </div>
                <div class="search">
                    <a-form layout='inline'>
                        <a-form-item label="">
                            <a-tooltip title="公告状态">
                            <a-select style="width: 140px" v-model='searchParams.search.type' allowClear placeholder="公告状态">
                                <a-select-option :value="1">公示中</a-select-option>
                                <a-select-option :value="2">已结束</a-select-option>
                            </a-select>
                            </a-tooltip>
                        </a-form-item>
                        <a-form-item>
                            <a-button @click="searchList" :loading="loading" type="primary" >搜索</a-button>
                        </a-form-item>
                    </a-form>
                </div>
            </div>
            <div 
            class="announce-box"
            @scroll="handleScroll"
            :style="{ height:wHeight}"
            v-infinite-scroll="handleInfiniteOnLoad"
            :infinite-scroll-disabled="busy"
            :infinite-scroll-distance="10"
            >
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-list item-layout="horizontal" :data-source="list">
                    <a-list-item ref="journalItem" slot="renderItem" slot-scope="item, index">
                        <div class="announce-item">
                            <div class="announce-item-title">
                                <img :src="item.created_by.employee_avatar">
                                <div>
                                    <div>
                                        <span>{{item.created_by.employee_name}}</span>
                                        <span style="color:#ccc;font-size:12px;margin-left:5px">{{item.is_read === 0?'未读':'已读'}}</span>
                                    </div>
                                    <div>{{item.created_at}}</div>
                                </div>
                            </div>
                            <div class="announce-item-nav">{{item.title}}</div>
                            <div class="ql-editor" v-html="item.content"></div>
                        </div>
                    </a-list-item>
                </a-list>
                <div class='announce-more' v-if="busy && list.length!=0">没有更多了~</div>
            </div>
        </div>
        <editModal v-if="isEditModal"/>
    </div>
</template>

<script>
    import editModal from './editModal'
    import infiniteScroll from 'vue-infinite-scroll'
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'
    export default {
        name:'announce',
        data() {
            return {
                activeKey:0,
                loading:false,
                searchDatas:{},
                searchParams: {
                    "page": 0,
                    "per-page": 10,
                    "search": {
                        type:1,
                        filter_type:''
                    },
                },
                list:[],
                myHeight:0,
                busy:false,
                isEditModal:false,
                filter_type:['','unread','me','receive'],
                minHeight:250,
                timers:'',
            }
        },
        provide() {
            return {
                parent: this
            }
        },
        components: {
            editModal,
        },
        computed:{
            wHeight(){
                return `${Number(this.myHeight) - 300}px`
            }
        },
        directives: { infiniteScroll },
        created () {
            this.myHeight = this.windowHeight()
            this.getList()
        },
        methods:{
            async getList(){
                this.loading = true
                this.searchParams.search.filter_type = this.filter_type[this.activeKey]
                let res = await this.$store.dispatch('oaAnnounceIndexAction',this.searchParams)
                if(res.items.length > 0){
                    this.list = [...this.list,...res.items]
                }else{
                    this.busy = true
                }
                if(this.searchParams.page == 1){
                    this.timers = setTimeout(()=>{
                        this.$nextTick(()=>{
                            if(this.$refs.journalItem){
                                let arr = this.$refs.journalItem.$parent.$children
                                arr.forEach((item,index)=>{
                                    if(item.$el.offsetTop < (Number(this.myHeight) - 450)){
                                        if(this.list[index].is_read === 0){
                                            this.toSeen(index)
                                        }
                                    }
                                })
                            }
                        })
                    },2000)
                }
                this.loading = false
            },
            changeTab(key) {
                clearTimeout(this.timers)
                this.activeKey = key
                this.searchParams.page = 1
                this.list = []
                this.busy = false
                this.minHeight = 250
                this.getList()
            },
            handleInfiniteOnLoad(){
                if(this.searchParams.page != 0){
                    if(!this.busy){
                        this.getList()
                    }
                }
                this.searchParams.page++
            },
            searchList(){
                this.searchParams.page = 1
                this.list = []
                this.minHeight = 250
                this.busy = false
                this.getList()
            },
            windowHeight() { 
                var de = document.documentElement
                return self.innerHeight||(de && de.offsetHeight)||document.body.offsetHeight;
            },
            showEditModal(){
                this.isEditModal = true
            },
            hideEditModal(val){
                if(val){
                    this.searchParams.page = 1
                    this.minHeight = 250
                    this.list = []
                    this.busy = false
                    this.getList()
                }
                this.isEditModal = false
            },
            handleScroll(e){
                if(e.target.scrollTop < this.minHeight) return false
                this.minHeight += 250
                let scrollTop = Number(e.target.scrollTop) + Number(e.target.offsetHeight)
                let arr = this.$refs.journalItem.$parent.$children
                let top = []
                arr.forEach((item,index)=>{
                    if(item.$el.offsetTop < scrollTop){
                        if(this.list[index].is_read === 0){
                            this.toSeen(index)
                        }
                    }
                })
            },
            async toSeen(index){
                await this.$store.dispatch('oaAnnounceSeenAction', {announcement_id:this.list[index].announcement_id})
                .then(res=>{
                    this.list[index].is_read = 1
                })
            },
        },
        beforeRouteLeave(to, from, next) {
            clearTimeout(this.timers)
            next()
        },
    }
</script>

<style lang="scss">
    .announce-main-box{
        width: 60%;
        padding: 0 15px;
        background: #fff;
    }
    .announce-box{
        overflow-y: scroll;
        .announce-item{
            border: 1px solid #e8e8e8;
            padding: 10px;
            width: 100%;
            border-radius: 3px;
            position: relative;
            .announce-item-title{
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                img{
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    margin-right: 5px;
                }
            }
            .announce-item-nav{
                text-align: center;
                padding: 5px 0;
                margin: 0 15px;
                background: #f4f7fd;
                border-radius: 5px;
                letter-spacing: .5px;
                font-size:14px;
                color:rgba(0, 0, 0, 0.65);
            }
            .ql-editor{
                img{
                    width: 100%;
                }
            }
        }
        .ant-list-item{
            border: none;
        }
    }
    .announce-more{
        text-align: center;
        padding: 10px 0;
    }
</style>